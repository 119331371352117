import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { UnstyledList } from "../global/common/essentials"
import { IndividualApproachInner } from "../web-support/IndividualApproach"

const Logos = styled(UnstyledList)`
  display: flex;
  flex-wrap: wrap;

  flex-shrink: 0;
  margin: 0 37px 0 -37px;

  @media ${minD.tabletL} {
    width: 50%;
  }

  @media ${maxD.tabletL} {
    margin: 0 -18px 20px;
  }
`

const LogoContainer = styled.li`
  width: calc(33.33% - 74px);
  margin: 16px 37px;

  @media ${maxD.laptop} {
    width: calc(33.33% - 36px);
    margin: 12px 18px;
  }

  @media ${maxD.tabletL} {
    width: calc(20% - 36px);
  }

  @media ${maxD.mobileM} {
    width: calc(33.33% - 36px);
  }
`

const Logo = styled.img`
  width: 100%;
  height: 52px;
  object-fit: contain;

  ${IndividualApproachInner} & {
    height: 42px;
  }
`

const LogosGallery = ({ logos }) => (
  <Logos>
    {logos &&
      logos.map(logo => (
        <LogoContainer key={`logo-${logo.id}`}>
          <Logo src={logo.localFile.publicURL} alt={logo.altText} />
        </LogoContainer>
      ))}
  </Logos>
)

export default LogosGallery
