import React from "react"
import styled from "styled-components"

import { minD, betweenD, maxD } from "../../../assets/styles/helpers/devices"

import { UnderlinedLink } from "../common/links"
import { DoubleSquare } from "../common/ornaments"
import { SecondaryTitle } from "../common/text"
import { GalleryItem, GrayBoxInner } from "./common"

const Box = styled(GalleryItem)`
  @media ${minD.tabletL} {
    top: 120px;

    &::after {
      padding-bottom: 200%;
    }
  }

  @media ${maxD.tablet} {
    order: -1;
  }
`

const BoxInner = styled(GrayBoxInner)`
  padding: 50px;

  @media ${betweenD.tabletLLaptop} {
    padding: 30px;
  }
`

const Title = styled(SecondaryTitle)`
  @media ${betweenD.tabletLLaptop} {
    font-size: 1.2rem;
  }
`

const Description = styled.p`
  @media ${betweenD.tabletLLaptop} {
    font-size: 0.7rem;
  }
`

const Link = styled(UnderlinedLink)`
  @media ${betweenD.tabletLLaptop} {
    font-size: 0.7rem;
  }
`

const DoubleDescription = ({
  title,
  description,
  buttonText,
  handleShuffleLinkClick,
}) => (
  <Box>
    <DoubleSquare as="div">
      <BoxInner>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
          style={{ marginBottom: "1.5rem" }}
        />
        <Link
          as="button"
          onClick={handleShuffleLinkClick}
          style={{ "--link-color": "var(--orange)" }}
        >
          {buttonText}
        </Link>
      </BoxInner>
    </DoubleSquare>
  </Box>
)

export default DoubleDescription
