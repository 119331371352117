import styled from "styled-components"

import { betweenD } from "../../../../assets/styles/helpers/devices"
import { greenBox } from "../../../global/common/ornaments"

const FeaturedImageContainer = styled.figure`
  position: relative;
  width: 100%;

  margin-right: var(--wrapper-right-padding);
  z-index: 9;

  ${greenBox}

  &::before {
    display: block;
    position: relative;
    content: "";

    padding-bottom: 100%;
  }

  &::after {
    right: 20px;
    top: -40px;
  }

  @media ${betweenD.tabletTabletL} {
    max-width: 70%;
    margin: calc(var(--section-spacing) * 0.1) 0
      calc(var(--section-spacing) * 0.5);
  }

  @media ${betweenD.mobileLTablet} {
    max-width: 85%;
  }
`

export default FeaturedImageContainer
