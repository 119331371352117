import styled from "styled-components"

import { minD, maxD } from "../../../assets/styles/helpers/devices"
import { overlay, Square } from "../common/ornaments"

export const TeamBox = styled(Square)`
  --member-texts-padding: 35px;

  position: relative;
  margin-bottom: calc(var(--member-texts-padding) * 1.5);

  &:not(:is(figure)) {
    @media ${maxD.tablet} {
      margin-bottom: 50px;
    }

    @media ${maxD.mobileL} {
      margin-bottom: 35px;
    }
  }

  &:is(figure) {
    &::after {
      content: none;
    }
  }

  .gatsby-image-wrapper > div:first-child[style] {
    /* just wtf gatsby image: https://prnt.sc/1ywiuya */

    padding-top: 100% !important;
  }
`

export const HeaderTeamBox = styled(TeamBox)`
  @media ${maxD.tablet} {
    &::after {
      content: none;
    }
  }
`

export const HeaderTeamBoxInner = styled.div`
  @media ${minD.tablet} {
    ${overlay}
  }
`
