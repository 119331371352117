import React from "react"

import { MiddleSpacedBigDescription } from "../common/text"

import { IntroSpacedOnMobile } from "./common"

const AboutUsHeaderIntro = ({ description }) => (
  <IntroSpacedOnMobile>
    <MiddleSpacedBigDescription
      dangerouslySetInnerHTML={{ __html: description.big }}
    />
    <p dangerouslySetInnerHTML={{ __html: description.small }}></p>
  </IntroSpacedOnMobile>
)

export default AboutUsHeaderIntro
