import React from "react"
import styled from "styled-components"

import { LineWrapper } from "../global/common/containers"
import { MainTitle } from "../global/common/text"
import {
  TeamBox,
  HeaderTeamBox,
  HeaderTeamBoxInner,
} from "../global/team/TeamBox"

import Invite from "../global/team/Invite"
import TeamMember from "../global/team/TeamMember"
import PostsContainer from "../global/atoms/PostsContainer"

import { minD, betweenD } from "../../assets/styles/helpers/devices"

const Container = styled(PostsContainer)`
  margin-bottom: calc(var(--section-spacing) * 0.5);

  @media ${betweenD.tabletLaptop} {
    grid-template-columns: 1fr 1fr;
  }
`

const TeamTitle = styled(MainTitle)`
  width: min(80%, calc(100vw - 50px));

  @media ${minD.tablet} {
    position: absolute;
  }
`

const Team = ({ data }) => {
  const title = data.aboutTeamHeader

  const invite = data.aboutTeamJoinUsText

  const button = {
    title: data.aboutTeamJoinUsLink?.title,
    url: data.aboutTeamJoinUsLink?.url,
    target: data.aboutTeamJoinUsLink?.target,
  }

  const team = data.aboutTeamMembers

  return (
    <LineWrapper>
      <Container as="section">
        <HeaderTeamBox as="header">
          <HeaderTeamBoxInner>
            <TeamTitle dangerouslySetInnerHTML={{ __html: title }} />
          </HeaderTeamBoxInner>
        </HeaderTeamBox>
        {team &&
          team.map(member => {
            if (!member.aboutTeamMember) return null

            const memberData = member.aboutTeamMember.acfTeamMember

            const id = member.aboutTeamMember.id
            const slug = member.aboutTeamMember.slug

            const name = memberData.singleUserName
            const position = memberData.singleUserPosition

            const imageUrl =
              memberData.singleUserImage.localFile.childImageSharp
                .gatsbyImageData

            return (
              <TeamBox as="figure" key={`team-member-${id}`}>
                <TeamMember
                  name={name}
                  slug={slug}
                  position={position}
                  imageUrl={imageUrl}
                />
              </TeamBox>
            )
          })}
        <TeamBox as="footer">
          <Invite title={invite} button={button} />
        </TeamBox>
      </Container>
    </LineWrapper>
  )
}

export default Team
