import React from "react"
import styled from "styled-components"

import { LineWrapper } from "./common/containers"
import { CenterTitleWide } from "./common/text"
import { TestimonialsInner } from "./testimonials/TestimonialsCarousel"

const TestimonialsContainer = styled.section`
  overflow: hidden;
`

const Testimonials = ({ data, containerStyle }) => {
  const header = data.testimonialsTitle

  return (
    <TestimonialsContainer style={containerStyle}>
      <LineWrapper as="div">
        <CenterTitleWide dangerouslySetInnerHTML={{ __html: header }} />
        <TestimonialsInner testimonials={data.testimonials} />
      </LineWrapper>
    </TestimonialsContainer>
  )
}

export default Testimonials
