import React from "react"
import styled from "styled-components"

import { minD } from "../../../assets/styles/helpers/devices"
import Arrow from "../../../assets/images/svg/right-arrow.svg"

import SiteLanguage from "../../../helpers/siteLanguage"
import optimizedPath from "../../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../../helpers/getRangedSrcSet"

const Testimonial = styled.figure`
  padding: 0 25px;
  position: relative;

  @media ${minD.tablet} {
    padding: 0 50px;
  }
  @media ${minD.tabletL} {
    max-width: 800px;
    margin: 0 auto;
  }
  @media ${minD.laptop} {
    padding: 0;
  }

  &::before {
    content: '';
    background-image: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="111.403" height="95.488" viewBox="0 0 111.403 95.488"><g id="quote" transform="translate(0 -29.235)"><path id="Path_40" data-name="Path 40" d="M233.882,29.235V76.979h31.829a31.866,31.866,0,0,1-31.829,31.829v15.915a47.8,47.8,0,0,0,47.744-47.744V29.235Z" transform="translate(-170.223)" fill="%23ebebeb"/><path id="Path_41" data-name="Path 41" d="M0,76.979H31.829A31.866,31.866,0,0,1,0,108.809v15.915A47.8,47.8,0,0,0,47.744,76.979V29.235H0Z" transform="translate(0 0)" fill="%23ebebeb"/></g></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 55px;
    height: 48px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;

    @media ${minD.tabletL} {
      width: 90px;
      height: 77px;
    }
  }
`

const TestimonialContent = styled.div`
  margin-bottom: 35px;
`

const TestimonialImage = styled.img`
  display: block;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: top;

  @media ${minD.tabletL} {
    min-width: 122px;
    height: 122px;
  }
`

const DescriptionContainer = styled.div``

const Description = styled.p`
  font-weight: 400;

  @media ${minD.tabletL} {
    font-size: 20px;
    line-height: 33px;
  }

  strong {
    font-weight: 700;
  }
`

const SignatureContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  @media ${minD.tablet} {
    gap: 30px;
  }
`

const ClientLogo = styled.img`
  width: min(40%, 160px);
  max-height: 90px;
  max-width: 160px;
  object-position: left;
  object-fit: contain;
  margin-left: auto;
`

const Signature = styled.div``

const SignatureName = styled.h3`
  margin-bottom: 8px;

  @media ${minD.tabletL} {
    font-size: 20px;
    line-height: 24px;
  }
`
const SignaturePosition = styled.p`
  font-weight: 300;

  @media ${minD.tabletL} {
    font-size: 15px;
    line-height: 18px;
  }

  span {
    color: #46AB39;

    @media ${minD.tabletL} {
      margin-top: 8px;
      display: inline-block;
    }
  }
`
const CaseStudyLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  color: #3ab54a;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    width: 20px;
    fill: #3ab54a;
  }
`

const SingleTestimonial = props => {
  const { link, content, testimonialname, position, logo, testimonialimage } = props.content

  return (
    <Testimonial>
      <TestimonialContent>
        <DescriptionContainer>
          <Description dangerouslySetInnerHTML={{ __html: content }}></Description>
          {link && (
            <CaseStudyLink 
              href={link.url} 
              target={link.target}
            >
              {link.title}
              <Arrow />
            </CaseStudyLink>
          )}
        </DescriptionContainer>
      </TestimonialContent>
      <SignatureContainer style={logo && {"justify-content": "space-between"}}>
        {testimonialimage && (
            <TestimonialImage
              loading="lazy"
              src={getImageBySrcSetAndSize(testimonialimage?.srcSet, 'small') ? getImageBySrcSetAndSize(testimonialimage?.srcSet, 'small') : optimizedPath(testimonialimage?.sourceUrl)}
              srcSet={getRangedSrcSet(testimonialimage?.srcSet, 'small')}
              alt={(SiteLanguage() !== "en" && testimonialimage?.atttachement_pl?.altTextPl) ? testimonialimage?.atttachement_pl?.altTextPl : testimonialimage?.altText}
            />
          )}
        <Signature>
          <SignatureName>{testimonialname}</SignatureName>
          <SignaturePosition dangerouslySetInnerHTML={{ __html: position }}></SignaturePosition>
        </Signature>
        {logo && (
          <ClientLogo
            loading="lazy"
            src={getImageBySrcSetAndSize(logo?.srcSet, 'small') ? getImageBySrcSetAndSize(logo?.srcSet, 'small') : optimizedPath(logo?.sourceUrl)}
            srcSet={getRangedSrcSet(logo?.srcSet, 'small')}
            alt={(SiteLanguage() !== "en" && logo?.atttachement_pl?.altTextPl) ? logo?.atttachement_pl?.altTextPl : logo?.altText}
          />
        )}
      </SignatureContainer>
    </Testimonial>
  )
}

export { SingleTestimonial }
