import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { MiddleSpacedBigDescriptionDiv, MainTitle } from "../global/common/text"
import { LineWrapperWithBottomSpace } from "../global/common/containers"
import { Button } from "../global/atoms/Button"

export const IndividualApproachInner = styled.article`
  max-width: 1000px;
  margin: -2.5rem auto 0;

  @media ${maxD.mobileL} {
    margin: -1rem auto 0;
  }
`
const ButtonCentered = styled(Button)`
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`

const IndividualApproach = ({
  data: {
    individualApproachGallery: gallery,
    individualApproachTitle: title,
    individualApproachDescription: description,
    individualApproachButton: button,
  },
}) => (
  <LineWrapperWithBottomSpace
    style={{
      "--does-bold-line-exists": "none",
    }}
  >
    <IndividualApproachInner>
      <MainTitle dangerouslySetInnerHTML={{ __html: title }} style={{ textAlign: 'center' }} />
      <MiddleSpacedBigDescriptionDiv
        className="content"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <ButtonCentered to={button.url} theme="green" target={button.target}>
        {button.title}
      </ButtonCentered>
    </IndividualApproachInner>
  </LineWrapperWithBottomSpace>
)

export default IndividualApproach
