import React from "react"

import LogosGallery from "./LogosGallery"

import { BigDescription } from "./common/text"
import { LineWrapper } from "./common/containers"
import { TechnologiesInner } from "./atoms/TechnologiesInner"

const Technologies = ({ description, technologies, containerStyle }) => (
  <LineWrapper
    style={{
      "--does-bold-line-exists": "none",
    }}
  >
    <TechnologiesInner style={containerStyle}>
      <LogosGallery logos={technologies} />
      <BigDescription dangerouslySetInnerHTML={{ __html: description }} />
    </TechnologiesInner>
  </LineWrapper>
)

export default Technologies
