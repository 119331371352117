import styled from "styled-components"

import { maxD } from "../../../assets/styles/helpers/devices"

export const TechnologiesInner = styled.div`
  display: flex;
  align-items: center;

  padding: 120px calc(var(--section-spacing) * 0.75)
    calc(var(--section-spacing) * 0.75);

  @media ${maxD.tabletL} {
    align-items: flex-start;
    flex-direction: column;

    padding: 120px 0 calc(var(--section-spacing) * 0.75);
  }

  @media ${maxD.tablet} {
    padding: 0 0 calc(var(--section-spacing) * 0.75);
  }
`
