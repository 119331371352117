import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import Nav from "../global/nav/Nav"
import HeaderContent from "../global/header/Content"
import HeaderImage from "../global/header/Image"
import AboutUsHeaderIntro from "../global/header/AboutUsIntro"
import HeaderGreenBoxOnTheRightSide from "../global/header/GreenBoxOnTheRightSide"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

import {
  BannerContainer,
  LineWrapper,
  LineWrapperWithBottomSpace,
} from "../global/common/containers"
import { Reviews } from "../global/Reviews"

const CustomBannerContainer = styled(LineWrapper)`
  @media ${maxD.tabletL} {
    flex-direction: column;
  }
`

const BannerWrapper = styled(LineWrapperWithBottomSpace)`
  --green-box-width: 500px;

  @media ${maxD.laptopL} {
    --green-box-width: min(350px, 100vw);
  }

  @media ${maxD.tabletL} {
    flex-direction: column;
  }
`

const AboutUsHeader = ({ pageContext, data }) => {
  const header = data.intro

  const image = {}
  if (data.featuredImage) {
    image.url = getImageBySrcSetAndSize(data.featuredImage?.srcSet, 'medium') ? getImageBySrcSetAndSize(data.featuredImage?.srcSet, 'medium') : optimizedPath(data.featuredImage?.sourceUrl),
    image.srcSet = getRangedSrcSet(data.featuredImage?.srcSet, 'medium'),
    image.alt = (SiteLanguage() !== "en" && data.featuredImage?.atttachement_pl?.altTextPl) ? data.featuredImage?.atttachement_pl?.altTextPl : data.featuredImage?.altText
  }

  const description = {}
  if (data.aboutIntroBigDescription)
    description.big = data.aboutIntroBigDescription
  if (data.aboutIntroSmallDescription)
    description.small = data.aboutIntroSmallDescription

  return (
    <>
      <BannerContainer>
        <Nav pageContext={pageContext} data={data} />
        <CustomBannerContainer
          relative
          flex
          spaceBetween
          style={{ zIndex: "9" }}
        >
          {header && <HeaderContent title={header} />}
          {image.url && <HeaderImage url={image.url} srcSet={image.srcSet} alt={image.alt} />}
        </CustomBannerContainer>
      </BannerContainer>
      <BannerWrapper relative flex spaceBetween>
        <HeaderGreenBoxOnTheRightSide containsSocialMedia />
        {description && <AboutUsHeaderIntro description={description} />}
        <Reviews vertical />
      </BannerWrapper>
    </>
  )
}

export default AboutUsHeader
