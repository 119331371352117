import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import "swiper/scss"

import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { maxD } from "../../../assets/styles/helpers/devices"

import GlobalSwiperStyles from "../../../assets/styles/components/Swiper"
import LightboxCloseButton from "./CloseButton"

import { SecondaryTitle } from "../common/text"
import { LightboxContainer } from "./common"
import { Brand } from "../nav/Brand"

SwiperCore.use([Navigation])

const CustomSwiperStyles = createGlobalStyle`
  .swiper-container-places {
    width: 100%;
    height: 100%;
  }

  .swiper-button-prev-places, 
  .swiper-button-next-places {
    top: calc(50% - var(--arrow-box-size));
  }

  .swiper-button-prev-places {
    left: var(--horizontal-slide-space);
  }

  .swiper-button-next-places {
    right: var(--horizontal-slide-space);
  }
`

const PlacesLightboxContainer = styled(LightboxContainer)`
  --vertical-slide-space: 80px;
  --horizontal-slide-space: 80px;

  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  z-index: var(--max-z-index);

  @media ${maxD.tabletL} {
    --vertical-slide-space: 50px;
    --horizontal-slide-space: 50px;
  }

  @media ${maxD.mobileL} {
    --vertical-slide-space: 35px;
    --horizontal-slide-space: 25px;
  }
`

const PlacesBrandContainer = styled.div`
  position: absolute;

  top: calc(var(--vertical-slide-space) + 12px);
  left: var(--horizontal-slide-space);
  z-index: calc(var(--max-z-index) - 1);

  transform: translateY(-50%);
`

const SlideContent = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 1;
  padding: var(--vertical-slide-space) var(--horizontal-slide-space);

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0) 25%,
    rgba(0, 0, 0, 0.5) 100%
  );
  color: #fff;
`

const SlideContentInner = styled.div`
  width: min(500px, 100%);
`

const PlacesLightbox = ({
  slides,
  isLightboxVisible,
  setLightboxVisibility,
}) => {
  const handleClose = () => {
    setLightboxVisibility(false)
  }

  return (
    <PlacesLightboxContainer className={isLightboxVisible && "visible"}>
      <GlobalSwiperStyles />
      <CustomSwiperStyles />
      <PlacesBrandContainer>
        <Brand logoClassName="white" />
      </PlacesBrandContainer>
      <Swiper
        className="swiper-container-places"
        navigation={{
          nextEl: ".swiper-button-next-places",
          prevEl: ".swiper-button-prev-places",
        }}
      >
        {slides &&
          slides.map((slide, i) => {
            const id = `gallery-item-${i}`

            const title = slide.placeTitle
            const description = slide.placeDescription

            const image =
              slide.placeImage?.localFile.childImageSharp.gatsbyImageData

            return (
              <SwiperSlide key={`place-${id}`}>
                <GatsbyImage image={image} alt="bg" className="full" />
                <SlideContent>
                  <SlideContentInner>
                    <SecondaryTitle
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  </SlideContentInner>
                </SlideContent>
              </SwiperSlide>
            )
          })}
        <button
          className="swiper-button-prev swiper-button-prev-places"
          aria-label="Previous slide"
        ></button>
        <button
          className="swiper-button-next swiper-button-next-places"
          aria-label="Next slide"
        ></button>
      </Swiper>
      <LightboxCloseButton
        onClick={handleClose}
        containerStyle={{
          top: "var(--vertical-slide-space)",
          right: "var(--horizontal-slide-space)",
        }}
        innerStyle={{ "--color": "#fff" }}
      />
    </PlacesLightboxContainer>
  )
}

export default PlacesLightbox
