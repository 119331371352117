import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { maxD } from "../../../assets/styles/helpers/devices"

import { SecondaryTitle } from "../../global/common/text"
import { notClickableButVisibleContent } from "../../../assets/styles/helpers/common"

const TeamMemberName = styled(SecondaryTitle)`
  position: absolute;

  left: var(--member-texts-padding);
  top: 0;
  padding-top: calc(100% - var(--member-texts-padding) * 1.5 - 0.5em);
  color: #fff;

  ${notClickableButVisibleContent}
`

const TeamMemberFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: calc(var(--member-texts-padding) * 0.75) var(--member-texts-padding)
    0;

  @media ${maxD.mobileL} {
    padding: calc(var(--member-texts-padding) * 0.75) 0 0;
  }
`

const TeamMemberPosition = styled.p`
  padding-right: var(--member-texts-padding);
`

const TeamMember = ({ name, position, imageUrl }) => (
  <>
    <GatsbyImage image={imageUrl} alt="bg" />
    <TeamMemberName
      as="h3"
      dangerouslySetInnerHTML={{ __html: name }}
      style={{ "--spacing-bottom": "0" }}
    />
    <TeamMemberFooter>
      <TeamMemberPosition>{position}</TeamMemberPosition>
    </TeamMemberFooter>
  </>
)

export default TeamMember
