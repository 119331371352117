import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../../assets/styles/layout"

import { FooterContainer } from "../../components/global/common/containers"

import AboutUsHeader from "../../components/about-us/AboutUsHeader"
import Gallery from "../../components/about-us/Gallery"
import Technologies from "../../components/global/Technologies"
import WhereWeAre from "../../components/about-us/WhereWeAre"
import Team from "../../components/about-us/Team"

import Testimonials from "../../components/global/Testimonials"

import CallToAction from "../../components/global/CallToAction"
import Footer from "../../components/global/Footer"
import Partners from "../../components/global/Partners"

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfCallToAction {
        ctaPersonPosition
        ctaPersonName
        ctaForm
        ctaImage {
          altText
          atttachement_pl {
            altTextPl
          }
          sourceUrl
          srcSet
        }
        ctaContactOptions {
          eMail
          phone
        }
        ctaButtons {
          ctaButton1 {
            url
            title
            target
          }
          ctaButton2 {
            target
            url
            title
          }
        }
        ctaIntro
        ctaTitle
      }
      acfAboutUs {
        aboutGallery {
          aboutGalleryTitleInner
          aboutGalleryInnerDescription
          aboutGalleryImage {
            altText
            atttachement_pl {
              altTextPl
            }
            sourceUrl
            srcSet
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        aboutGalleryButtonText
        aboutGalleryExists
        aboutGallerySubtitle
        aboutGalleryTitle
        aboutIntroBigDescription
        aboutIntroSmallDescription
        aboutTeamExists
        aboutTeamHeader
        aboutTeamMembers {
          aboutTeamMember {
            ... on WpTeamMember {
              acfTeamMember {
                singleUserImage {
                  altText
                  atttachement_pl {
                    altTextPl
                  }
                  sourceUrl
                  srcSet
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                  }
                }
                singleUserPosition
                singleUserName
              }
              id
              slug
            }
          }
        }
        aboutTeamJoinUsText
        aboutTeamJoinUsLink {
          url
          title
          target
        }
        aboutTechnologiesDescription
        aboutTechnologiesExists
        aboutTechnologiesGallery {
          id
          altText
          atttachement_pl {
            altTextPl
          }
          sourceUrl
          srcSet
          localFile {
            publicURL
          }
        }
        aboutTestimonialsExists
        aboutWhereWeAreButton
        aboutWhereWeAreDescription
        aboutWhereWeAreExists
        aboutWhereWeAreImage {
          altText
          atttachement_pl {
            altTextPl
          }
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        places {
          placeDescription
          placeTitle
          placeImage {
            altText
            atttachement_pl {
              altTextPl
            }
            sourceUrl
            srcSet
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        aboutWhereWeAreTitle
        fieldGroupName
        intro
        featuredImage {
          altText
          atttachement_pl {
            altTextPl
          }
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        testimonialsTitle
        testimonials {
          ... on WpTestimonial {
            id
            acfSingleTestimonials {
              content
              testimonialname
              position
              link {
                target
                title
                url
              }
              logo {
                altText
                atttachement_pl {
                  altTextPl
                }
                sourceUrl
                srcSet
              }
              testimonialimage {
                altText
                atttachement_pl {
                  altTextPl
                }
                srcSet
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`

const AboutUs = ({ pageContext, data: { wpPage } }) => {
  const wpAboutUs = wpPage.acfAboutUs
  const { seo } = wpPage

  const is = {
    testimonials: wpAboutUs.aboutTestimonialsExists,
    gallery: wpAboutUs.aboutGalleryExists,
    technologies: wpAboutUs.aboutTechnologiesExists,
    whereWeAre: wpAboutUs.aboutWhereWeAreExists,
    team: wpAboutUs.aboutTeamExists,
  }

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpPage} />
      )}
      <AboutUsHeader data={wpAboutUs} pageContext={pageContext} />
      {is.testimonials && (
        <Testimonials
          data={wpAboutUs}
          containerStyle={{ backgroundColor: "var(--light-gray)" }}
        />
      )}
      {is.gallery && <Gallery data={wpAboutUs} />}
      {is.technologies && (
        <Technologies
          description={wpAboutUs.aboutTechnologiesDescription}
          technologies={wpAboutUs.aboutTechnologiesGallery}
        />
      )}
      {is.whereWeAre && <WhereWeAre data={wpAboutUs} />}
      {is.team && <Team data={wpAboutUs} />}
      <Partners style={{ paddingTop: "0" }} />
      <FooterContainer>
        <CallToAction data={wpPage.acfCallToAction} />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default AboutUs
