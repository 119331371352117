import React, { useEffect, useRef } from "react"
import styled, { createGlobalStyle } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import "swiper/scss"

import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

import GlobalSwiperStyles from "../../../assets/styles/components/Swiper"
import LightboxCloseButton from "./CloseButton"
import { SecondaryTitle } from "../common/text"
import { LightboxContainer } from "./common"

SwiperCore.use([Navigation])

const CustomSwiperStyles = createGlobalStyle`
  .swiper-container-descriptions {
    position: static;
  }

  .swiper-button-prev-descriptions, 
  .swiper-button-next-descriptions {
    top: calc(var(--slide-space) * -1 - var(--arrow-box-size) * 0.7);
  }

  .swiper-button-prev-descriptions {
    left: calc(var(--arrow-box-size) * -1);

    @media ${maxD.laptop} {
      left: calc(var(--slide-space) * -1 + 20px);
    }
  }

  .swiper-button-next-descriptions {
    right: calc(var(--arrow-box-size) * -1);

    @media ${maxD.laptop} {
      right: calc(var(--slide-space) * -1 + 20px);
    }
  }
`

const DescriptionsLightboxContainer = styled(LightboxContainer)`
  --slide-space: 100px;

  position: absolute;
  width: calc(100% - var(--wrapper-right-padding));

  top: var(--section-spacing);
  left: 0;

  background-color: var(--main-green);

  @media ${maxD.laptop} {
    --slide-space: 60px;
  }

  @media ${maxD.mobileL} {
    --slide-space: 25px;

    width: 100%;
  }
`

const SlidesContainer = styled.div`
  position: relative;

  margin: var(--slide-space);
`

const SlideContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${maxD.tabletL} {
    flex-wrap: wrap;
  }

  @media ${maxD.mobileL} {
    margin: var(--slide-space) 1px calc(var(--slide-space) * 0.4);
  }
`

const SlideTitle = styled(SecondaryTitle)`
  @media ${maxD.mobileL} {
    padding-top: 1rem;
  }
`

const SlideContent = styled.article`
  color: var(--off-white);

  @media ${minD.tabletL} {
    padding-left: calc(var(--slide-space) - 1rem);
  }

  @media ${maxD.tabletL} {
    padding-top: calc(var(--slide-space) - 1rem);
  }
`

const LightboxImage = styled(GatsbyImage)`
  width: 45%;
  flex-shrink: 0;
  height: min(calc(100vw - 50px), 50vh);

  @media ${maxD.tabletL} {
    width: calc(100% - 40px);
  }

  @media ${maxD.tablet} {
    height: 300px;
  }

  @media ${maxD.mobileL} {
    height: 200px;
  }
`

const Lightbox = ({
  slides,
  currentSlide,
  isLightboxVisible,
  setLightboxVisibility,
}) => {
  const swiper = useRef(null)

  useEffect(() => {
    swiper.current.swiper.slideTo(currentSlide, false, false)
  }, [currentSlide])

  const handleClose = () => {
    setLightboxVisibility(false)
  }

  return (
    <DescriptionsLightboxContainer className={isLightboxVisible && "visible"}>
      <GlobalSwiperStyles />
      <CustomSwiperStyles />
      <SlidesContainer>
        <Swiper
          autoHeight="true"
          ref={swiper}
          className="swiper-container-descriptions"
          navigation={{
            nextEl: ".swiper-button-next-descriptions",
            prevEl: ".swiper-button-prev-descriptions",
          }}
        >
          {slides &&
            slides.map((slide, i) => {
              const id = `gallery-item-${i}`

              const title = slide.aboutGalleryTitleInner
              const description = slide.aboutGalleryInnerDescription

              const image = {
                image:
                  slide.aboutGalleryImage?.localFile.childImageSharp
                    .gatsbyImageData,
                alt: slide.aboutGalleryImage?.altText,
              }

              return (
                <SwiperSlide key={`big-${id}`}>
                  <SlideContainer>
                    <LightboxImage {...image} />
                    <SlideContent>
                      <SlideTitle
                        dangerouslySetInnerHTML={{ __html: title }}
                        style={{ "--spacing-bottom": "2rem" }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: description }}></p>
                    </SlideContent>
                  </SlideContainer>
                </SwiperSlide>
              )
            })}
          <button
            className="swiper-button-prev swiper-button-prev-descriptions"
            aria-label="Previous slide"
          ></button>
          <button
            className="swiper-button-next swiper-button-next-descriptions"
            aria-label="Next slide"
          ></button>
        </Swiper>
      </SlidesContainer>
      <LightboxCloseButton
        onClick={handleClose}
        containerStyle={{ top: "45px" }}
        innerStyle={{ "--color": "#fff" }}
      />
    </DescriptionsLightboxContainer>
  )
}

export default Lightbox
