import React from "react"
import styled from "styled-components"
import CloseButtonInner from "../atoms/CloseButtonInner"

const CloseButton = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  position: absolute;

  width: 20px;
  height: 20px;

  top: 30px;
  right: min(40px, 7vw);

  z-index: calc(var(--max-z-index) - 1);
`

const LightboxCloseButton = ({ onClick, containerStyle, innerStyle }) => (
  <CloseButton aria-label="Close" onClick={onClick} style={containerStyle}>
    <CloseButtonInner style={innerStyle} />
  </CloseButton>
)

export default LightboxCloseButton
