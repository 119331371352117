import React from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css/pagination';

import "swiper/scss"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

import GlobalSwiperStyles from "../../../assets/styles/components/Swiper"
import { SingleTestimonial } from "./SingleTestimonial"

SwiperCore.use([Navigation, Pagination])

const innerSwiperStyles = css`
  .swiper-button-prev-testimonials,
  .swiper-button-next-testimonials {
    top: 25%;
    transform: translateY(-50%);
    height: 40px;
    width: 40px;

    @media ${minD.mobileL} {
      height: 58px;
      width: 58px;
    }
  }

  .swiper-slide:not(.swiper-slide-active) {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  .swiper-button-prev-testimonials {
    left: -25px;
    transform: translateY(-50%) rotate(180deg);

    @media ${minD.mobileL} {
      left: -35px;
    }
    @media ${minD.tabletL} {
      left: 0;
    }
      @media ${minD.laptopL} {
        left: 90px;
      }
  }

  .swiper-button-next-testimonials {
    right: -25px;

    @media ${minD.mobileL} {
      right: -35px;
    }
      @media ${minD.tabletL} {
      right: 0;
    }
      @media ${minD.laptopL} {
        right: 90px;
      }
  }

  .swiper-button-disabled {
    display: none;
  }
`

const Container = styled(Swiper)`
  ${innerSwiperStyles}

  padding: 40px 0 var(--section-spacing);

  @media ${maxD.mobileL} {
    padding: 50px 0;
  }

  &::before {
    left: -25px;

    @media ${maxD.tabletL} {
      top: 25%;
    }
  }

  &::after {
    left: calc(66.66% - 25px);

    @media ${maxD.laptop} {
      left: calc(50% - 25px);
      top: 60%;
    }

    @media ${maxD.tabletL} {
      top: 30%;
    }
  }
`

const Button = styled.button`
  @media ${minD.laptop} {
    ${({ visibleOnDesktop }) =>
      visibleOnDesktop ||
      css`
        display: none;
      `}
  }
`

const PaginationContainer = styled.div`
  position: static;
  margin-top: 30px;

  .swiper-pagination-bullet {
    width: 21px;
    height: 3px;
    background-color: #959595;
    border-radius: 0;

    @media ${minD.tablet} {
      width: 42px;
      height: 6px;
      margin: 0 10px !important;
      opacity: 1;
      transition: 0.3s ease-out;
    }

    @media (hover: hover) {
      &:hover {
        background-color: #aadfb1;
      }
    }
  }
  .swiper-pagination-bullet-active {
    background-color: #46AB39;

    @media (hover: hover) {
      &:hover {
        background-color: #46AB39;
      }
    }
  }
`

const TestimonialsInner = ({ testimonials }) => (
  <>
    <GlobalSwiperStyles />
    <Container
      loop={false}
      slidesPerView={1}
      breakpoints={{
        767: {
          slidesPerView: 1,
        },
        1279: {
          slidesPerView: 1,
        },
      }}
      navigation={{
        nextEl: ".swiper-button-next-testimonials",
        prevEl: ".swiper-button-prev-testimonials",
      }}
      pagination={{ el: ".swiper-pagination", clickable: true }}
      className="swiper-container-testimonials"
    >
      {testimonials &&
        testimonials.map((testimonial, i) => {
          const isReversed = { "--direction": "column" }
          return (
            <SwiperSlide style={isReversed} key={`${testimonial.id}-${i}`}>
              <SingleTestimonial content={testimonial.acfSingleTestimonials} />
            </SwiperSlide>
          )
        })}
      <Button
        className="swiper-button-prev swiper-button-prev-testimonials"
        aria-label="Previous slide"
        visibleOnDesktop={testimonials && testimonials.length > 3}
      />
      <Button
        className="swiper-button-next swiper-button-next-testimonials"
        aria-label="Next slide"
        visibleOnDesktop={testimonials && testimonials.length > 3}
      />
      <PaginationContainer className="swiper-pagination" />
    </Container>
  </>
)

export { TestimonialsInner }
