import { createGlobalStyle } from "styled-components"

import { arrow } from "./../../../components/global/common/ornaments"

const GlobalSwiperStyles = createGlobalStyle`
  .swiper-slide  {
    transition: opacity 200ms ease;  
  }

  .swiper-button-prev, 
  .swiper-button-next {
    ${arrow}

    position: absolute;
    z-index: 9;
  }

  .swiper-button-prev {
    transform: rotate(180deg);
  } 

  .swiper-button-disabled {
    background-color: var(--dark-gray);
  }
`

export default GlobalSwiperStyles
