import React from "react"
import styled from "styled-components"

import { MainTitleH1 } from "../common/text"
import { HeaderInner } from "./common"

import { Button } from "../atoms/Button"

const SpacedTitle = styled(MainTitleH1)`
  margin-bottom: 1.2rem;
`

const HeaderContent = ({ title, button }) => (
  <HeaderInner>
    <SpacedTitle
      dangerouslySetInnerHTML={{ __html: title }}
      style={{ lineHeight: "1.45" }}
    />
    {button && button.url && (
      <Button
        to={button.url}
        target={button.target}
        theme="green"
        containerStyle={{
          marginTop: "calc(var(--section-spacing) * 0.2)",
        }}
      >
        {button.title}
      </Button>
    )}
  </HeaderInner>
)

export default HeaderContent
