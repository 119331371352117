import React from "react"

import { GalleryItem, ImageLink } from "./common"

const ClassicImage = ({ url, fullUrl, srcSet, alt, onImageLinkClick }) => (
  <GalleryItem onClick={onImageLinkClick}>
    <ImageLink as="a" href={fullUrl}>
      <img src={url} srcSet={srcSet} alt={alt} className="full" />
    </ImageLink>
  </GalleryItem>
)

export default ClassicImage
