import React, { useState } from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { Button } from "../global/atoms/Button"

import SectionWithIndentedTitle from "../global/SectionWithIndentedTitle"
import PlacesLightbox from "../global/gallery/PlacesLightbox"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const SectionContent = styled.article`
  @media ${minD.tabletL} {
    width: 600px;
    flex-shrink: 0;
  }

  @media ${minD.laptop} {
    padding-left: calc(var(--container-left-margin-desktop, 120px) * 0.6);
  }

  @media ${minD.desktop} {
    width: 700px;
    padding-left: var(--container-left-margin-desktop, 120px);
  }

  @media ${maxD.tablet} {
    padding-top: var(--section-spacing);
  }
`

const WhereWeAre = ({ data }) => {
  const [isLightboxVisible, setLightboxVisibility] = useState(false)
  const title = data.aboutWhereWeAreTitle

  const image = {
    url: getImageBySrcSetAndSize(data.aboutWhereWeAreImage?.srcSet, 'medium') ? getImageBySrcSetAndSize(data.aboutWhereWeAreImage?.srcSet, 'medium') : optimizedPath(data.aboutWhereWeAreImage?.sourceUrl),
    srcSet: getRangedSrcSet(data.aboutWhereWeAreImage?.srcSet, 'medium'),
    alt: (SiteLanguage() !== "en" && data.aboutWhereWeAreImage?.atttachement_pl?.altTextPl) ? data.aboutWhereWeAreImage?.atttachement_pl?.altTextPl : data.aboutWhereWeAreImage?.altText
  }

  const description = data.aboutWhereWeAreDescription
  const buttonText = data.aboutWhereWeAreButton

  const places = data.places

  return (
    <>
      <SectionWithIndentedTitle
        title={title}
        image={image}
        containerStyle={{
          backgroundColor: "var(--light-gray)",
          marginBottom: "var(--section-spacing)",
        }}
        titleStyle={{ "--space": "70px", maxWidth: "790px" }}
        contentStyle={{ "--content-align": "center" }}
        imageStyle={{ maxHeight: "500px" }}
      >
        <SectionContent>
          <p
            dangerouslySetInnerHTML={{ __html: description }}
            style={{ paddingBottom: "2.5rem" }}
          ></p>
          <Button
            theme="green"
            as="button"
            onClick={() => setLightboxVisibility(true)}
          >
            {buttonText}
          </Button>
        </SectionContent>
      </SectionWithIndentedTitle>
      <PlacesLightbox
        slides={places}
        isLightboxVisible={isLightboxVisible}
        setLightboxVisibility={setLightboxVisibility}
      />
    </>
  )
}

export default WhereWeAre
