import React from "react"
import styled from "styled-components"

import { ornament } from "../../../assets/styles/helpers/common"

const ButtonInner = styled.span`
  --color: var(--main-green);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:before,
  &:after {
    ${ornament}

    height: 20px;
    width: 2px;
    background-color: var(--color);
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const CloseButtonInner = ({ style }) => <ButtonInner style={style} />

export default CloseButtonInner
