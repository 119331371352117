import React from "react"
import styled from "styled-components"

import Plus from "../../../assets/images/svg/plus.svg"

import { Button } from "../../global/atoms/Button"
import { SecondaryTitle } from "../../global/common/text"
import { overlay } from "../../global/common/ornaments"

const Container = styled.div`
  ${overlay}
  height: calc(100% - calc(var(--member-texts-padding) * 0.75) - 3.56rem);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--dark-gray);
`

const StyledPlus = styled(Plus)`
  width: 70px;
  height: 70px;
  fill: #616161;
`

const Invite = ({ title, button }) => (
  <Container>
    <StyledPlus />
    <SecondaryTitle
      dangerouslySetInnerHTML={{ __html: title }}
      style={{ color: "#fff", "--spacing-bottom": "40px", paddingTop: "30px" }}
    />
    {button && button.url && (
      <Button theme="green" to={button.url} target={button.target}>
        {button.title}
      </Button>
    )}
  </Container>
)

export default Invite
