import React from "react"

import { GalleryItem, ImageLinkWithOverlay } from "./common"

const GreenImage = ({ url, srcSet, fullUrl, alt, onImageLinkClick }) => (
  <GalleryItem onClick={onImageLinkClick}>
    <ImageLinkWithOverlay href={fullUrl} as="a">
      <img src={url} srcSet={srcSet} alt={alt} className="full" />
    </ImageLinkWithOverlay>
  </GalleryItem>
)

export default GreenImage
