import React from "react"

import { Overlay } from "../../global/common/ornaments"
import FeaturedImageContainer from "../gallery/atoms/FeaturedImageContainer"

function HeaderImage({ url, alt, srcSet }) {
  return (
    <FeaturedImageContainer>
      <Overlay rel="preload" src={url} alt={alt} srcSet={srcSet} style={{ position: "absolute" }} />
    </FeaturedImageContainer>
  )
}

export default HeaderImage
